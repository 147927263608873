import classNames from 'classnames'
import { useRouter } from 'next/router'
import { useState } from 'react'

import { AnnouncementBar, AnnouncementsContextProvider } from 'components/announcements'
import { ContentContainer } from 'components/contentContainer'
import { Header } from 'components/header'
import { Navbar } from 'components/navbar'
import { useRouterPages } from 'components/navbar/hooks'
import { Initialize } from 'components/ui/initialize'
import { Overlay } from 'components/ui/overlay'
import { AnnouncementBarProvider } from 'contexts/announcementBarContext'
import { FloatingBannerProvider } from 'contexts/floatingBannerContext'
import { NavigationContextProvider } from 'contexts/navigationContext'
import { useScrollYParams } from 'hooks/useScrollYParams'
import { THEME, useTheme } from 'state/storage'
import { CookieBanner } from './cookieBanner'

export function Ui({ children }) {
    const theme = useTheme()
    const router = useRouter()

    const { isStakeAppPage, isSwapAppPage, isPortfolioDashboard } = useRouterPages()

    const [isShowAnnouncementBar, setIsShowAnnouncementBar] = useState(true)

    useScrollYParams()

    const isBrandedStakeApp = isStakeAppPage && router?.query?.locked === 'true'
    const isBrandedSwapApp = isSwapAppPage && router?.query?.outputlocked === 'true'

    const isHyperliquidBestDeal =
        isStakeAppPage && router?.query?.input === 'hyperliquid-testnet' && router?.query?.['best-deal'] === 'true'

    const isBrandedPage = isBrandedStakeApp || isBrandedSwapApp

    return (
        <FloatingBannerProvider>
            <AnnouncementBarProvider>
                <div
                    id='ui'
                    className={classNames('max-h-screen', {
                        'dark:bg-black bg-shade-lightest':
                            String(router.asPath).startsWith('/sreth') || String(router.asPath).startsWith('/404'),
                        'bg-wb': isPortfolioDashboard && theme === THEME.DARK,
                        '!bg-[#072320]': isHyperliquidBestDeal,
                    })}
                    data-theme={theme}
                >
                    <NavigationContextProvider>
                        <Initialize />
                        <AnnouncementsContextProvider>
                            <div className='flex flex-col w-full h-screen'>
                                {isShowAnnouncementBar ? (
                                    <AnnouncementBar setIsShow={setIsShowAnnouncementBar} />
                                ) : null}
                                <div className='flex w-full h-full overflow-x-hidden overflow-y-auto'>
                                    <Navbar />
                                    <div className='flex flex-col w-full overflow-x-hidden overflow-y-auto'>
                                        <Overlay />
                                        <Header />
                                        <ContentContainer>{children}</ContentContainer>
                                    </div>
                                </div>
                            </div>
                        </AnnouncementsContextProvider>
                    </NavigationContextProvider>
                    <CookieBanner />
                </div>
            </AnnouncementBarProvider>
        </FloatingBannerProvider>
    )
}
